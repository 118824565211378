
import { Component, Vue } from 'nuxt-property-decorator'
import { Events } from '@simple-payments/shared-packages/types/enum/Events'

@Component
export default class extends Vue {
  mounted () {
    // const gtm = this.$gtm
    const eventBus = this.$eventBus

    /**
     * преход к поиску
     */
    eventBus.$on(Events.SEARCH_FORM_SUBMIT, () => {})

    /**
     * результат поиска
     */
    eventBus.$on(Events.SEARCH_RESULT, () => {})

    /**
     * посадка с письма
     */
    eventBus.$on(Events.EMAIL_RESULT, () => {})

    /**
     * офомление подписки
     */
    eventBus.$on(Events.SUBSCRIBE, () => {})

    /**
     * успешная оплата
     */
    eventBus.$on(Events.PAYMENT_SUCCESS, () => {})

    eventBus.$on(Events.PAYMENT_FRAME_LOADED, () => {})

    /**
     * подписка на браузерные пуши
     */
    eventBus.$on(Events.PUSH_SUBSCRIBE, () => {})

    /**
     * ввод данных пользователя
     */
    eventBus.$on(Events.PAYER_INFO_SUBMIT, () => {})
  }
}
